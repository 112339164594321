// This is a manifest file that'll be compiled into global_dependencies.js,
// which will include the files listed below that reside on the global namespace
// and are tricky to load with webpack.

import $ from "jquery"
import "jquery-ujs"
import "jquery-ui/ui/widgets/autocomplete"
import "jquery-ui/ui/widgets/sortable"

// Attaches jQuery to the window object if you need it globally
window.$ = $
window.jQuery = $

$(function () {
  document.dispatchEvent(new Event("jquery_loaded"))
})
